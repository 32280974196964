@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Chivo-Italic";
  color: #908c8c;
  background-color: #dfe3ef;
  height: 100vh;
}

@font-face {
  font-family: 'Chivo-Italic';
  src: url('./assets/fonts/Chivo-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Chivo-MediumItalic';
  src: url('./assets/fonts/Chivo-MediumItalic.ttf') format('truetype');
}

a {
  text-decoration: none;
}